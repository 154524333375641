export let BASE_URL = {url: 'https://37.46.130.153:3001'};

// export let BASE_URL = {url: 'http://localhost:3000'};



export const API_URLS = {
  getAllMatches: '/matches/all',
  getMatch: '/matches/',
  setAllResults: '/matches/setAllResults',
  setMatchData: '/matches/setMatchData',
  setMatchExtraData: '/matches/setMatchExtraData',
  setMatchBestPlayer: '/matches/setBestPlayer',
  unsetMatchBestPlayer: '/matches/unsetBestPlayer',

  login: '/users/login/admin',
  logout: '/users/logout',
  refreshTokens: '/users/refreshTokens',

  getMatchActiveApplication: '/matches/getRosterEvents',

  getAllStadiums: '/stadiums/getAllStadiums',

  getAllReferees: '/judges/getAllReferees',

  getAllAdminLeagues: '/leagues/allAdminLeagues',

  getRoster: '/teams/:teamId/applications',

  getAdminTeams: '/teams/getAdminTeams',


  getAvailableTeamApplications: '/teams/adminAvailableApplications',
  getTeamApplication: '/teams/getTeamApplicationQuery',
  getPlayerApplications:'/players/:playerId/adminApplicationsWithLeagues',
  declineApplication: '/teams/declineTeamApplication',
  acceptApplication: '/teams/acceptTeamApplication',

  searchPlayersByParams: '/players/search',

  getBlackList: '/leagues/adminBlackList',
  getPlayerFromBlackList: '/leagues/adminBlackListPlayer',
  addToBlackList: '/leagues/adminBlackListAdd',
  changeBlackList: '/leagues/adminBlackListChange',
  deleteFromBlackList: '/leagues/adminBlackListDrop'

};

export const leftTextFirstSlide = 
'Подключайте свою футбольную любительскую лигу на платформу мобильного приложения inGame! ' +
'Первый месяц - бесплатно. '+
'Перенесём всю историю ваших чемпионатов. Постоянная техническая поддержка.'

export const rightTextFirstSlide = 
'Скачивайте приложение и смотрите статистику и информацию о матчах своей лиги! Оставляйте свои комментарии, впечатления и пожелания, с помощью вас мы сможем стать еще лучше.'
